export default function MesStacks() {
  const hardskills = [
    { name: "HTML", level: "90%", type: "Frontend" },
    { name: "CSS", level: "90%", type: "Frontend" },
    { name: "JavaScript", level: "70%", type: "Frontend" },
    { name: "SASS", level: "80%", type: "Frontend" },
    { name: "Tailwind", level: "80%", type: "Frontend" },
    { name: "Bootstrap", level: "80%", type: "Frontend" },
    { name: "JavaScript", level: "70%", type: "Backend" },
    { name: "PHP", level: "70%", type: "Backend" },
    { name: "MongoDB", level: "50%", type: "Database" },
    { name: "SQL", level: "50%", type: "Database" },
    { name: "MySQL", level: "50%", type: "Database" },
    { name: "React", level: "80%", type: "Frameworks" },
    { name: "Next.js", level: "70%", type: "Frameworks" },
    { name: "Node.js", level: "70%", type: "Frameworks" },
    { name: "Express", level: "50%", type: "Frameworks" },
    { name: "Symfony", level: "70%", type: "Frameworks" },
  ];
  const types = [...new Set(hardskills.map((skill) => skill.type))];
  return (
    <div class=" pt-10 flex flex-col container w-4/5 bg-[#001329] drop-shadow-xl rounded-3xl sm:w-full mx-auto">
      <p class=" sm:p-10 pt-29 text-center text-white text-3xl font-semibold tracking-tight text-[#201515] sm:text-5xl">
        Hard Skills
      </p>
      <div class="flex flex-wrap justify-between p-8 rounded-lg">
        {types.map((type) => (
          <div key={type} className="sm:w-1/2 w-full p-10">
            <h2 className="text-primary">{type} Skills:</h2>
            <ul>
              {hardskills
                .filter((skill) => skill.type === type)
                .map((filteredSkill) => (
                  <li class="flex flex-col justify-between items-left py-2">
                    <span className="flex justify-between py-3 ">
                      <span class="text-white text-sm ">
                        {filteredSkill.name}
                      </span>
                      <span class="px-2 py-1 bg-bg rounded-lg text-xs text-primary font-medium min-w-[46px] text-center">
                        {filteredSkill.level}
                      </span>
                    </span>
                    <span className="flex w-full relative">
                      <span
                        class=" absolute bg-bg h-1 rounded"
                        style={{ width: "100%" }}
                      ></span>
                      <span
                        class="relative bg-primary h-1 rounded"
                        style={{ width: filteredSkill.level }}
                      ></span>
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
