export default function MesProjects() {
  const projects = [
    {
      name: "Atypik House",
      description:
        "AtypikHouse est une société de location d’habitations insolites, comme les cabanes dans les arbres. Mon objectif dans le cadre de mon examen de fin d’études était de fournir à AtypikHouse un site internet, accessible sur tous les supports.",
      stack: "Wordpress, PHP, MySQL",
      link: "http://dsp3-bd-o20-g3.walltchougwa.fr",
      image: "./images/atypik.png",
    },
    {
      name: "Pokedex",
      description: "Une application qui permet de consulter les informations détaillées sur les Pokémon, y compris leurs types, statistiques, évolutions et descriptions.",
      stack: "React, Tailwind, Node.js",
      link: "https://pokedex-react-liard-nine.vercel.app",
      image: "./images/pokemon.png",
    },
    {
      name: "LetsPlay",
      description: "L'application permet à deux personnes de s'affronter en ligne ou sur un même appareil en jouant à puissance4. Il suffit de rentrer son pseudo et de se connecter la même partie",
      stack: "React, Node.js, Express, Spcket.io",
      link: "https://lets-play-front.vercel.app",
      image: "./images/letsplay.png",
    },
    {
      name: "Spheriim",
      description:
        "Ce projet vise à créer un site Web pour les élèves et leurs enseignants. Chaque élève a un profil avec des informations importantes relatives au cours.",
      stack: "React, Node.js,Superbase, TypeScript, TailwindCSS",
      link: "https://spheriim-walltchs-projects.vercel.app/auth/login",
      image: "./images/spheriim.png",
    },
  ];

  return (
    <div class=" sm:p-10 pt-29 mt-10 container w-4/5 bg-[#001329] drop-shadow-xl rounded-3xl sm:w-full mx-auto">
      <p class=" sm:p-10 pt-29 text-center text-white text-3xl font-semibold tracking-tight text-[#201515] sm:text-5xl">
        Mes Projets
      </p>
      <div class="flex flex-wrap justify-between p-8 rounded-lg">
        {projects.map((project) => (
          <div
            key={project.name}
            className="sm:w-1/2 w-full py-10 sm:h-max sm:p-10"
          >
            <a href={project.link}>
              <img
                class="rounded-t-lg"
                src={project.image}
                alt={project.name}
              />
            </a>
            <div class="sm:p-5">
              <a href={project.link}>
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-primary dark:text-white">
                  {project.name}
                </h5>
              </a>
              <p class="mb-3 font-normal text-white ">{project.description}</p>
              <p class="mb-3 font-normal text-white ">
                Environnement : {project.stack}
              </p>
              <a
                href={project.link}
                class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Voir le siteweb
                <svg
                  class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
