export default function QuiSuiJe() {
  return (
    <div class="container w-4/5 sm:w-full mx-auto flex flex-wrap justify-between sm:mx-auto content-center">
      <div class="flex flex-col justify-evenly max-w-xl text-center lg:text-left gap-12 lg:inline-flex md:order-first">
        <p class=" mt-10 text-white text-3xl font-semibold tracking-tight text-[#201515] sm:text-5xl">
          À propos de moi
        </p>
        <p class=" text-white max-w-xl mt-4 text-base tracking-tight text-gray-600">
          Actuellement en première année de mastère Développeur Fullstack, je
          recherche une alternance pour ma deuxième année en tant que
          développeur fullstack, front-end ou back-end. Je suis motivé à
          contribuer activement aux projets de l'entreprise et à mettre en
          pratique mes connaissances en développement web. Passionné par les
          nouvelles technologies et le travail en équipe, je souhaite intégrer
          une structure dynamique où je pourrai relever des défis techniques et
          évoluer professionnellement.
        </p>
        <div class=" flex justify-center sm:justify-start py-4 space-x-4">
          <a
            href="./images/cv.pdf"
            class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            download="walltchougwa.pdf"
          >
            Télécharger cv
          </a>
          <a
            href="mailto:tchougwandjiogo@gmail.com"
            class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Me contacter
          </a>
        </div>
      </div>
      <div class="flex items-center order-first block w-full mt-12 aspect-square lg:mt-0 sm:w-2/5">
        <img
          class="object-cover rounded-3xl object-center w-full mx-auto bg-gray-300 lg:ml-auto "
          alt="hero"
          src="./images/wall.png"
        />
      </div>
    </div>
  );
}
