import React from "react";
import LandingPage from "./components/LandingPage";
import QuiSuiJe from "./components/QuiSuiJe";
import MesStacks from "./components/MesStacks";
// import NavBar from "./components/NavBar";
import MesProjects from "./components/MesProjets";
function App() {
  return (
    <>
      {/* <NavBar /> */}
      <LandingPage />
      <QuiSuiJe />
      <MesStacks />
      <MesProjects />
    </>
  );
}
export default App;
